import React from 'react';
import { Link } from 'react-router-dom';
import './ServiceCard.scss';

function ServiceCard() {
  return (
    <section className='serviceCard_container'>
        <div className="left_content">
            <h1>Why choose us?</h1>
            <p>We envisions a world of fairness and equality in which people with intellectual and developmental disabilities can live more fully integrated within the community. We work to change the world’s view of those we serve as we help them discover their individual gifts and abilities, and we seek to promote greater compassion and understanding of those people who make up our community. </p>
            <Link to='/contact' className='btn'>Contact Us</Link>
        </div>

        <div className="right_content">
            <div className="card1"></div>
            <div className="card2"></div>
        </div>
    </section>
  )
}

export default ServiceCard;
import React from 'react';
import './Mission.scss';
import { Link } from 'react-router-dom';

function Mission() {
  return (
    <section className='mission_container'>
        <h1>Our Mission</h1>
        <p>
          Our mission is to provide compassionate and high-quality home care services that allow individuals to live safely and comfortably in their own homes.We are committed to creating personalized care plans that meet the unique needs and preferences of each individual client.
          Our goal is to help our clients maintain independence, dignity, and the highest possible level of function while remaining in the comfort of their own home.
        </p>
        <Link to='./contact' className='btn' data-aos="fade-up">Contact Us</Link>
    </section>
  )
}

export default Mission;
import React from 'react';
import { TabTitle } from '../utils/GeneralFunction';
import Hero from '../components/About-Components/Hero/Hero'
import Footer from '../components/About-Components/Footer/Footer'
import AboutUs from '../components/About-Components/About/AboutUs';
import OurTeam from '../components/About-Components/OurTeam/OurTeam';
import EnimIcon from '../components/About-Components/EnimIcon/EnimIcon';
import ContactUs from '../components/About-Components/contact/ContactUs';


function About() {
  TabTitle('wellbeing home - about');

  return (
    <>
      <Hero />
      <AboutUs />
      <EnimIcon />
      <OurTeam />
      <ContactUs />
      <Footer />
    </>
  )
}

export default About;
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa';
import './Navbar.scss';
import logo from '../../../assets/color-logo.png';

function Navbar() {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);


  return (
    <div className='nav_container'>
        <div className="logo">
            <Link to='/'><img src={logo} alt="logo" /></Link>
        </div>

        <div className={click ? "nav_list active" : "nav_list"}>
            <Link to='/' className='link'>Home</Link>
            <Link to='/about' className='link active'>About</Link>
            <Link to='/service' className='link'>Services</Link>
            <Link to='/contact' className='link'>Contact Us</Link>
        </div>
        <div className="hamburger" onClick= {handleClick}>
          {click ? <FaTimes size={35} style={{color: "#fff"}}/> : <FaBars size={35} style={{color: "#fff"}}/>}
        </div>
    </div>
  )
}

export default Navbar;
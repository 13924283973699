import React from 'react';
import { TabTitle } from '../utils/GeneralFunction';
import Hero from '../components/Contact-Component/Hero/Hero';
import MapBanner from '../components/Contact-Component/Map/Map_banner';
import Footer from '../components/Contact-Component/Footer/Footer';


function Contact() {
  TabTitle('wellbeing home - contact');

  return (
    <>
      <Hero />
      <MapBanner />
      <Footer />
    </>
  )
}

export default Contact;
import React from 'react';
import './EnimIcon.scss';

function EnimIcon() {
  return (
    <section className='content_container'>
        <h5>Endless love & care</h5>
        <h1>Elderly care done right: our approach to home care services</h1>

        <div className="card_container">
            <div className="card_wrapper">
                <div className="icon1">
                </div>
                <h4 data-aos="fade-down">Regular check-ins:</h4>
                <p data-aos="fade-down">Conduct regular check-ins with clients to ensure that their needs are being met and to make any necessary adjustments to their care plan.</p>
            </div>

            <div className="card_wrapper">
                <div className="icon2"></div>
                <h4 data-aos="fade-down">Care coordination:</h4>
                <p data-aos="fade-down">It is important to  coordinate care between different providers to ensure continuity and avoid duplication of services.</p>
            </div>
            
            <div className="card_wrapper">
                <div className="icon3"></div>
                <h4 data-aos="fade-down">Support for caregivers: </h4>
                <p data-aos="fade-down">Provide support and resources for family caregivers to help them manage their caregiving responsibilities.</p>
            </div>
        </div>
    </section>
  )
}

export default EnimIcon;
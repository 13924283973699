import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './Hero.scss';

function Hero() {
  return (
    <section className='hero_section'>
        <Navbar />
        
        <div className="content">
            <h1>Experience compassionate and professional home care services.</h1>
        </div>
        <Link to='/contact' className='btn'>Contact Us</Link>
    </section>
  )
}

export default Hero;
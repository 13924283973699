import React from 'react';
import './Banner.scss';

function Banner() {
  return (
    <>
        <div className="banner_container">
          <div className="left_container" data-aos="zoom-out">
              <h4>Services we offer</h4>
              <h1>Our home care solutions are designed as a comprehensive & highly standardized package.</h1>
          </div>
        </div>
    </>
  )
}

export default Banner;
import React from 'react';
import { TabTitle } from '../utils/GeneralFunction';
import Hero from '../components/Services-Coomponent/Hero/Hero';
import ContactUs from '../components/Services-Coomponent/Contact/ContactUs';
import Footer from '../components/Services-Coomponent/Footer/Footer';
import Stepper  from '../components/Services-Coomponent/Step/Stepper';
import ServiceCard from '../components/Services-Coomponent/ServiceCard/ServiceCard';
import Banner from '../components/Services-Coomponent/Banner/Banner';
import Anjaan from '../components/Services-Coomponent/OurService/Anjaan';


function Services() {
  TabTitle('wellbeing home - services');

  return (
    <>
      <Hero />
      <Stepper />
      <ServiceCard />
      <Banner />
      <Anjaan />
      <ContactUs />
      <Footer />
    </>
  )
}

export default Services;
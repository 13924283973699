import React from 'react';
import "./Contact.scss";

function Contact() {
  return (
    <section className='contact_container'>
        <div className="content">
            <h1>Get in touch with us for any questions or concerns</h1>
            <p>we are available to help you out!</p>
        </div>

        <form>
            <div className='input-field'>
                <label htmlFor="name">Name</label>
                <input type="text" name='name' id='name' required />
            </div>

            <div className='econ'>
                <div className='input-field'>
                    <label htmlFor="email">E-mail</label>
                    <input type="email" name='email' id='email' required />
                </div>

                <div className='input-field'>
                    <label htmlFor="phone">Phone</label>
                    <input type="number" name='phone' id='phone' required />
                </div>
            </div>

            <div className='input-field'>
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" cols="30" rows="6"></textarea>
            </div>

            <input type="submit" value="Send" name='submit' id='btn' />
        </form>
    </section>
  )
}

export default Contact;
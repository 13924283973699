import React from 'react';
import Navbar from '../Navbar/Navbar';
import './Hero.scss';

function Hero() {
  return (
    <section className='hero_service_section'>
        <Navbar />
        
        <div className="content">
            <h1>Your home is your safe haven, and our goal is to take the stress out of it.</h1>
        </div>
        <p>
        We take pride in delivering around the clock and quality home care services to our elder loved ones. 
        </p>
    </section>
  )
}

export default Hero
import React from 'react';
import './Map_banner.scss';


function MapBanner() {
  return (
    <>
      <iframe className='map_container' title="location" src="https://maps.google.com/maps?width=785&amp;height=494&amp;hl=en&amp;q=3601 Portland Ave Minneapolis, MN 55407&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    </>
  )
}

export default MapBanner;
import React from 'react';
import './Stepper.scss';

function Stepper() {
  return (
    <section className='stepper_container'>
        <h1>Elements that make Wellbeing Home Care out of the ordinary.</h1>

        <div className="card_container">
          <div className="card_wrapper" data-aos="fade-up-left">
            <div className="card">
              <div className="circle">1</div>
              <h2>Believe</h2>
              <p>We believe in a new way to grow services and create long-term relationships.</p>
            </div>

            <div className="line">
              <div className="start"></div>
              <div className="dashed"></div>
              <div className="dashed xl_dash"></div>
              <div className="dashed xl_dash"></div>
              <div className="dashed xl_dash"></div>
              <div className="dashed"></div>
              <div className="dashed"></div>
              <div className="end"></div>
            </div>
          </div>

          <div className="card_wrapper" data-aos="fade-up-left">
            <div className="card">
              <div className="circle">2</div>
              <h2>Belongingness</h2>
              <p>We make sure that every aspect of our services reflects the moment you first felt like you belonged.</p>
            </div>

            <div className="line">
              <div className="start"></div>
              <div className="dashed"></div>
              <div className="dashed"></div>
              <div className="dashed xl_dash"></div>
              <div className="dashed xl_dash"></div>
              <div className="dashed xl_dash"></div>
              <div className="dashed"></div>
              <div className="end"></div>
            </div>
          </div>

          <div className="card_wrapper" data-aos="fade-up-left">
            <div className="card">
              <div className="circle">3</div>
              <h2>Betterment</h2>
              <p>The quality of life is an important thing to have and our seniors deserve to live their best lives.</p>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Stepper;
import React from 'react';
import './Anjaan.scss';

function Anjaan() {
  return (
    <section className='anjaan_container'>
        <div className="right_content">
            <div className="col1">
                <div className="top">
                    <h5>Transportation</h5>
                    <p>Wellbeing Home Care emphasizes the importance of participants being active in the community rather than being isolated at home. For this reason, The Wellbeing Home Care has a car and an available staff member to drive participants to appointments, employment, shopping, recreational activities, and other outings.</p>
                </div>

                <div className="bottom">
                    <h5>Culturally Specific Services</h5>
                    <p>Wellbeing Home Carer’s ability to provide culturally specific services comes from the top. Having Executive staff members who are from the community creates a home atmosphere that welcomes and reaffirms the residents.</p>
                </div>
            </div>

            <div className="col2">
                <div className="top">
                    <h5>24 hour staffing</h5>
                    <p>Wellbeing Home Care provides 24-hour staffing at its home, with at least two staff members on duty around the clock. In addition to those two, a third staff member works in the afternoons from 3:00 to 7:00 with primary responsibilities of cooking and cleaning, but secondary responsibility to assist residents when needed.</p>
                </div>

                <div className="bottom">
                    <h5>Person Centered Practices</h5>
                    <p>Our history of providing a culturally appropriate residential climate is indicative of the care taken to address each individual’s needs.This begins with respect and honor for the person and the culture, beliefs, and practices that make that person who they are. That requires understanding and is best provided by those who share the same background.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Anjaan;
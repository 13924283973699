import React from 'react';
import './OurTeam.scss';

function OurTeam() {
  return (
    <section className='team_container'>
        <h6>OUR TEAM</h6>
        <h1>Get to know the individuals making it all happen.</h1>

        <div className="card_container">
            <div className="card_wrapper">
                <div className="card1"></div>
                <div className="details">
                    <h5>Abdi Mahamed</h5>
                    <p>Executive director</p>
                </div>
            </div>

            {/* <div className="card_wrapper" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="25000">
                <div className="card2"></div>
                <div className="details">
                    <h5>Dr. Cripspin Semakula</h5>
                    <p>President</p>
                </div>
            </div> */}

            {/* <div className="card_wrapper" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="25000">
                <div className="card3"></div>
                <div className="details">
                    <h5>Name Surname</h5>
                    <p>Designation or Role</p>
                </div>
            </div>
            
            <div className="card_wrapper" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="25000">
                <div className="card4"></div>
                <div className="details">
                    <h5>Name Surname</h5>
                    <p>Designation or Role</p>
                </div>
            </div> */}
        </div>
    </section>
  )
}

export default OurTeam;
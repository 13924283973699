import React  from 'react';
import Navbar from '../Navbar/Navbar';
import './Hero.scss';

function Hero() {
  return (
    <section className='hero_about_section'>
        <Navbar />
        
        <div className="content">
            <h1>Caring is the first step to making a difference in the world.</h1>
        </div>
        <p>
          We choose compassion and kindness to make the society loving and peaceful. 
        </p>
    </section>
  )
}

export default Hero
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/footer-logo.png'
import './Footer.scss'

function Footer() {
  return (
    <section className='footer_container'>
        <div className="footer_nav">
            <div className="logo">
                <Link to= "/"><img src={logo} alt="logo" /></Link>
            </div>

            <div className="links">
                <Link to="/about" className='link'>About</Link>
                <Link to="/service" className='link'>Services</Link>
                <Link to="/contact" className='link'>Contact</Link>
            </div>
        </div> 

        <div className="content_wrapper">
            <div className="left_content">
                <p>
                    We understand the importance of maintaining a healthy and safe environment in the home. Trust us to provide top-notch home care services.
                </p>

            </div>

            <div className="right_content">
                <div className="details">
                    <h4>Address :</h4>
                    <p>- 3601 Portland Ave Minneapolis, MN 55407</p>

                    <p>- 312 10th street SW Willmar MN 56201</p>
                </div>

                <div className="details">
                    <h4>Contact Number :</h4>
                    <span>(612) 986-3354</span>
                    <br />
                    <h4>Fax Number :</h4>
                    <span>(612) 233-3133</span>
                </div>

                <div className="details">
                    <h4>E-mail Address :</h4>
                    <span>wellbeinghomecarellc@gmail.com</span>
                </div>
            </div>
        </div>

        <div className="line"></div>

        <div className="policy">
            <p>privacy policy</p>
            <p>site map</p>
        </div>
    </section>
  )
}

export default Footer;
import React from 'react';
import './About.scss';

function AboutUs() {
  return (
    <section className='aboutUs_container'>
        <div className="top_content">
          <h1>“Find out why we do what we do.”</h1>
        </div>

        <div className="bottom_content">
          <div className="left_img"></div>
          <div className="right_content">
            <h1>ABOUT US</h1>
            <p>
              {/* We are a dedicated team of professionals committed to providing exceptional home care services for individuals in need. Our goal is to enhance the well-being and quality of life of our clients by offering standardized care plans and compassionate support. With our skilled and experienced staff, we strive to make a positive impact on the lives of those we serve. */}

              Wellbeing Home Care Center is a 245D Corporate Adult Foster Care provider located at 312 10th Street SW in Willmar, MN. We specialize in providing in-home services to adults aged 18 and older with disabilities. We have a team of 24-hour nursing staff. <br />
              We have two open beds available for clients who require specialised care and support. We accept clients with EW, AC, DD, CADI & TBI Waivers.<br />
              Our team of nursing staff is well-trained and experienced in all aspects of nursing care, and provides 24-hour supervision and home care aide tasks. <br />
              We understand that mental health crises can happen at any time, and our staff is equipped with the skills necessary to de-escalate these situations quickly and safely. <br />
              If you are looking for a reliable and compassionate care provider for yourself or a loved one, please contact us today to learn more about the services we offer. We are committed to helping our clients live their best lives, and we look forward to working with you to achieve your goals.
            </p>
          </div>
        </div>
    </section>
  )
}

export default AboutUs;
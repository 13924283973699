import React from 'react';
import "./Review.scss";
import icon from "../../../assets/review-icon.png";

function Review() {
  return (
    <section className='review_container'>
        <h1>What Clients Say About Us</h1>

        <div className="card_container">
            <div className="card_wrapper">
                <div className="card">
                    <img src={icon} alt="icon" />
                    <p>“Their caregivers are highly skilled and trained, and always take the time to understand my specific needs and preferences. They are also very reliable and always arrive on time.”</p>

                    <div className="info">
                        <h4>John Smith</h4>
                        <p>Managing Director</p>
                    </div>
                </div>
                
                <div className="card">
                    <img src={icon} alt="icon" />
                    <p>
                        "I was impressed with the level of communication and coordination with the care team and my family. I would definitely recommend Wellbeing Home Care Services to anyone looking for high-quality home care services."
                    </p>

                    <div className="info">
                        <h4>Rose Kaif</h4>
                        <p>CEO</p>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
  )
}

export default Review;
import React from 'react';
import './SupportCard.scss';

import userIcon from '../../../assets/user-icon.png';
import heartIcon from '../../../assets/heart-icon.png';
import chairIcon from '../../../assets/chair-icon.png';

function SupportCard() {
  return (
    <section className='support_container'>
        <h1>Core values</h1>
        
        <div className="card_container">
            <div className="card">
                <img src={userIcon} alt="userIcon" />
                <h4>Compassion</h4>
                <p>Showing empathy and understanding towards clients and their loved ones.</p>
            </div>

            <div className="card">
                <img src={heartIcon} alt="heartIcon" />
                <h4>Integrity</h4>
                <p>Acting with honesty and transparency in all interactions and decisions.</p>
            </div>

            <div className="card">
                <img src={chairIcon} alt="chairIcon" />
                <h4>Responsiveness</h4>
                <p> Being attentive to patient needs and being responsive to their concerns and requests.
                </p>
            </div>
        </div>
    </section>
  )
}

export default SupportCard;
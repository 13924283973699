import React  from 'react';
import { Link } from 'react-router-dom';
import './TopServices.scss';

import card1 from '../../../assets/card-1.png'
import card2 from '../../../assets/home_card2.png'
import card3 from '../../../assets/home_card3.png'

function TopServices() {
  return (
    <section className='topservices_container'>
        <h1>Explore our top quality Services</h1>

        <div className="image_wrapper">
            <div className="left_img" >
                <img src={card1} alt="card1" />
                <div className="card_body">
                  <h2 className='card_title'>Culturally Specific</h2>
                  <p className='card_para'>
                  Wellbeing Home Carer’s ability to provide culturally specific services comes from the top. Having Executive staff members who are from the various community.
                  </p>
                  <Link to='/service' className='card_btn'>Learn More</Link>
                </div>
            </div>

            <div className="right_img">
                <div className="img1">
                  <img src={card2} alt="card2" />
                  <div className="card_body">
                    <h2 className='card_title'>24 hour staffing</h2>
                    <p className='card_para'>
                      Wellbeing Home Care provides 24-hour staffing at its home, with at least two staff members on duty around the clock.
                    </p>
                    <Link to='/service' className='card_btn'>Learn More</Link>
                  </div>
                </div>

                <div className="img2">
                  <img src={card3} alt="card3" />
                    <div className="card_body">
                      <h2 className='card_title'>Person Centered</h2>
                      <p className='card_para'>
                      Our history of providing a culturally appropriate residential climate is indicative of the care taken to address each individual’s needs.
                      </p>
                      <Link to='/service' className='card_btn'>Learn More</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default TopServices;
import React from 'react';
import Navbar from '../Navbar/Navbar';
import './Hero.scss';

function Hero() {
  return (
    <section className='hero_section_contact'>
        <Navbar />
        
        <div className="form_container">
          <div className="top_content">
            <h4>we are available to help you out!</h4>
            <h1>Get in touch with us for any questions or concerns</h1>
            <p>We have a dedicated customer support team available to assist with any questions or concerns.</p>
          </div>

          <form className='form'>
            <div className="row1">
              <div className="input_fields">
                <label htmlFor="fname">First Name</label>
                <input type="text" id='fname' name='fname' required />
              </div>

              <div className="input_fields">
                <label htmlFor="lname">Last Name</label>
                <input type="text" id='lname' name='lname' required />
              </div>
            </div>

            <div className="row2">
              <div className="input_fields">
                <label htmlFor="email">Email</label>
                <input type="email" id='email' name='email' required />
              </div>

              <div className="input_fields">
                <label htmlFor="phone">Phone No.</label>
                <input type="number" id='phone' name='phone' required />
              </div>
            </div>

            <input type="submit" id='submit' value={'Send'} name="submit" />
          </form>

          <div className="contact-details">
            <div className="details">
              <h5>Address</h5>
              <p className='add'>- 3601 Portland Ave Minneapolis, MN 55407</p>

              <p className='add'>- 3601 Portland Ave Minneapolis, MN 55407</p>
            </div>

            <div className="details">
              <h5>Opening Time</h5>
              <p>10:00 am to 7:00pm</p>
              <h5>E-mail Address</h5>
              <p>wellbeinghomecarellc@gmail.com</p>
            </div>

            <div className="details">
              <h5>Contact Details</h5>
              <p>(612) 986-3354</p>

              <h5>Fax Details</h5>
              <p>(612) 233-3133</p>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Hero
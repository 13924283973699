import React from 'react';
import Contact from '../components/Home-Components/Contact/Contact';
import Footer from '../components/Home-Components/Footer/Footer';
import Hero from '../components/Home-Components/Hero/Hero';
import Mission from '../components/Home-Components/Mission/Mission';
import Review from '../components/Home-Components/Review/Review';
import SupportCard from '../components/Home-Components/SupportCard/SupportCard';
import TopServices from '../components/Home-Components/TopServices/TopServices';
import { TabTitle } from '../utils/GeneralFunction';


function Home() {
  TabTitle('wellbeing home - home');

  return (
    <>
      <Hero />
      <SupportCard />
      <Mission />
      <TopServices />
      <Contact />
      <Review />
      <Footer />
    </>
  )
}

export default Home;
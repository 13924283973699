import React from 'react';
import './Contact.scss';

function ContactUs() {
  return (
    <section className='Contact_container'>
        <div className="top_content">
          <h4>we are available to help you out!</h4>
          <h1>Get in touch with us for any questions or concerns</h1>
          <p>We have a dedicated customer support team available to assist with any questions or concerns.</p>
        </div>

        <form className='form'>
          <div className="row1">
            <div className="input_fields">
              <label htmlFor="fname">First Name</label>
              <input type="text" id='fname' name='fname' required />
            </div>

            <div className="input_fields">
              <label htmlFor="lname">Last Name</label>
              <input type="text" id='lname' name='lname' required />
            </div>
          </div>

          <div className="row2">
            <div className="input_fields">
              <label htmlFor="email">Email</label>
              <input type="email" id='email' name='email' required />
            </div>

            <div className="input_fields">
              <label htmlFor="phone">Phone No.</label>
              <input type="number" id='phone' name='phone' required />
            </div>
          </div>

          <input type="submit" id='submit' value={'Send'} name="submit" />
        </form>
    </section>
  )
}

export default ContactUs;